import React from 'react';
import { Link } from 'gatsby';
import { PhotographIcon, BookOpenIcon } from '@heroicons/react/outline';
import learn from '../images/icons/learn.svg';
import DictLink from './shared/DictLink';

const features = [
  {
    name: 'Japanese-English picture dictionary',
    href: 'https://app.kumalearn.com/dictionary',
    cta: 'Go to dictionary',
    description: (
      <>
        <div>
          Visual content plays a big role in our learning and keeping us motivated,
          whether that be watching anime, reading manga or watching TV.
        </div>
        <div className="mt-2">
          We&apos;ve carefully hand-picked 4,000+ words with pictures for you,
          so that you can look up Japanese vocab visually!
        </div>
      </>
    ),
    icon: PhotographIcon,
  },
  {
    name: 'eBooks packed with hand-picked vocab',
    href: '/ebooks',
    cta: 'Browse eBooks',
    description: (
      <>
        Learning Japanese vocabulary with pictures certainly helps but we
        have plans to make it <em>even more</em> fun by introducing interactive games.
      </>
    ),
    icon: BookOpenIcon,
  },
  {
    name: (
      <div className="flex flex-col items-start">
        <div>
          Spaced Repetition System (SRS)
        </div>
      </div>
    ),
    href: 'https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=features',
    cta: 'Learn with SRS',
    description: (
      <>
        Kuma Learn helps you learn Japanese vocabulary and
        kanji <em>efficiently</em> with
        a <Link href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=features" className="underline">Spaced Repetition System (SRS)</Link>. You&apos;ll be shown words that
        you don&apos;t know more often than words know well.
      </>
    ),
    icon: () => (
      <img src={learn} width="24" alt="Kuma Learn helps you learn efficiently with Spaced Repetition System (SRS)" />
    ),
  },
];

const Features = () => (
  <div className="bg-white">
    {/* Header */}
    <div className="relative pb-32">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover object-top"
          src="https://static.kumalearn.com/images/tokyo-tower-resized.jpg"
          alt="Tokyo Tower"
        />
        <div className="absolute inset-0 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="absolute top-4 right-4">
        <figcaption className="mt-3 flex items-center text-sm text-gray-500">
          <svg className="flex-none w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
          </svg>
          <span className="ml-2 text-xs"><Link className="underline" to="/attributions">Attributions</Link></span>
        </figcaption>
      </div>
      <div className={[
        'relative max-w-7xl mx-auto py-24 px-4',
        'flex flex-col items-start',
        'sm:py-32 sm:px-6 lg:px-8',
      ].join(' ')}>
        <h1 className="text-4xl font-extrabold tracking-tight text-white backdrop-blur-xs md:text-5xl lg:text-6xl">Learn visually</h1>
        <p className="mt-6 max-w-3xl text-xl text-gray-300 backdrop-blur-xs">
          Kuma Learn offers you a <DictLink>Japanese-English picture dictionary</DictLink> as
          well as <Link to="/ebooks" className="underline">eBooks with hand-picked vocabulary</Link>.
          Kuma Learn also offers a <Link href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=features" className="underline">Spaced Repetition System (SRS)</Link> so
          that you can learn vocab and kanji efficiently.
        </p>
      </div>
    </div>

    {/* Overlapping cards */}
    <section
      className="-mt-32 max-w-7xl mx-auto relative z-1 px-4 sm:px-6 lg:px-8"
      aria-labelledby="features-heading"
    >
      <h2 className="sr-only" id="features-heading">
        Benefits and features
      </h2>
      <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
        {features.map((feature) => (
          <div key={feature.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
            <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
              <div className="absolute top-0 p-5 inline-block bg-green-600 rounded-xl shadow-lg transform -translate-y-1/2">
                <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">{feature.name}</h3>
              <p className="mt-4 text-base text-gray-500">{feature.description}</p>
            </div>
            <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
              {feature.to && (
                <Link
                  to={feature.to}
                  className="text-base font-medium text-green-700 hover:text-green-600"
                >
                  {feature.cta}
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              )}
              {feature.href && (
                <a
                  href={feature.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-base font-medium text-green-700 hover:text-green-600"
                >
                  {feature.cta}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              )}
              {!(feature.to || feature.href) && (
                <span className="text-base font-medium text-gray-500">
                  {feature.cta}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  </div>
);

export default Features;
