import React, { useState } from 'react';
import { Link } from 'gatsby';
import Typist from 'react-typist';
import { Helmet } from 'react-helmet';
import SEO from '../components/shared/SEO';
import Footer from '../components/shared/Footer';
import SubscriptionForm from '../components/shared/SubscriptionForm';
import Faqs from '../components/shared/FAQ';
import Features from '../components/Features';
import Maker from '../components/shared/Maker';
import DictLink from '../components/shared/DictLink';
import GiveFeedback from '../components/shared/GiveFeedback';
import coverCards from '../images/cover_cards.png';
import logo from '../images/logo_with_text.svg';
import reddit from '../images/reddit.svg';
import gumroad from '../images/gumroad.svg';

const CAROUSEL = [
  'to visit Japan',
  'to watch anime',
  'to speak with locals',
  'to read manga',
  'to impress friends',
  'to have fun learning',
];

const Menu = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
        <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/">
                <span className="sr-only">Kuma Learn</span>
                <div className="flex flex-row items-center">
                  <img src={logo} className="h-8 w-auto sm:h-10" alt="Kuma Learn" />
                </div>
              </Link>
              <div className="-mr-2 flex items-center md:hidden">
                <button
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                  id="main-menu"
                  aria-haspopup="true"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
            <Link to="/about" className="text-sm font-medium text-gray-500 hover:text-gray-900">About</Link>
          </div>
          <div className="hidden md:block md:ml-2 md:pr-2 md:space-x-8">
            <a
              href="https://app.kumalearn.com/dictionary?utm_medium=web&utm_source=www&utm_campaign=menu"
              className="text-sm flex items-center font-medium text-gray-500 hover:text-gray-900"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Dictionary
            </a>
          </div>
          <div className="hidden md:block md:ml-2 md:pr-2 md:space-x-8">
            <a
              href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=menu"
              className="text-sm flex items-center font-medium text-gray-500 hover:text-gray-900"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              SRS
            </a>
          </div>
          <div className="hidden md:block md:ml-2 md:pr-2 md:space-x-8">
            <Link to="/ebooks" className="text-sm font-medium text-gray-500 hover:text-gray-900">eBooks</Link>
          </div>
          <div className="hidden md:block md:ml-2 md:pr-2 md:space-x-8">
            <a
              href="https://blog.kumalearn.com?utm_medium=web&utm_source=www&utm_campaign=menu"
              className="text-sm font-medium text-gray-500 hover:text-gray-900"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Blog
            </a>
          </div>
          <div className="hidden md:block md:ml-2 md:pr-2 md:space-x-8">
            <a
              href="https://kumalearn.gumroad.com/l/lrrro"
              className="text-sm font-medium text-gray-500 hover:text-gray-900"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Buy me coffee
            </a>
          </div>
        </nav>
      </div>

      {open && (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 py-4 flex items-center justify-between">
              <div className="flex items-center">
                <img src={logo} className="h-8 w-auto sm:h-10" alt="Kuma Learn" />
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close main menu</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
              <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                <Link to="/about" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">About</Link>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                <a
                  href="https://app.kumalearn.com/dictionary?utm_medium=web&utm_source=www&utm_campaign=menu"
                  className="flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Dictionary
                </a>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                <a
                  href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=menu"
                  className="flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SRS
                </a>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                <Link to="/ebooks" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" role="menuitem">eBooks</Link>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                <a
                  href="https://blog.kumalearn.com?utm_medium=web&utm_source=www&utm_campaign=menu"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Blog
                </a>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                <a
                  href="https://kumalearn.gumroad.com/l/lrrro"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Buy me a coffee
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SocialProof = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto pt-12 px-4 sm:px-6 lg:pt-16 lg:px-8">
      <h2 className="sr-only">As seen on social media</h2>
      <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
        As seen on
      </p>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-0.5 lg:mt-8">
        <div className="col-span-1 flex justify-center py-2 px-8">
          <div>
            <a
              className="flex flex-col items-center"
              href="https://www.reddit.com/r/LearnJapanese/comments/kxu6io/learn_japanese_vocabulary_with_pictures_jlptn5/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="max-h-12" src={reddit} alt="Reddit /r/LearnJapanese" height="36px" width="111px" />
              <h3 className="mt-2 ml-2 text-center text-base font-semibold text-gray-500 tracking-wider">/r/LearnJapanese</h3>
            </a>
            <div className="flex items-baseline mt-2">
              <a
                className="underline"
                href="https://www.reddit.com/r/LearnJapanese/comments/kxu6io/learn_japanese_vocabulary_with_pictures_jlptn5/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <h3 className="text-center text-base text-gray-500 tracking-wider">First post</h3>
              </a>
              <div className="mx-2">
                /
              </div>
              <a
                className="underline"
                href="https://www.reddit.com/r/LearnJapanese/comments/n91xp4/learn_japanese_vocabulary_with_pictures_jlptn32/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <h3 className="text-center text-base text-gray-500 tracking-wider">Second post</h3>
              </a>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex justify-center py-2 px-8 mt-4 sm:mt-0">
          <div>
            <a
              className="flex flex-col items-center"
              href="https://kumalearn.gumroad.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="max-h-12" src={gumroad} alt="Gumroad" height="36px" width="36px" />
              <h3 className="mt-2 ml-2 text-center text-base font-semibold text-gray-500 tracking-wider">Gumroad</h3>
            </a>
            <a
              className="underline"
              href="https://kumalearn.gumroad.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <h3 className="text-center text-base text-gray-500 tracking-wider">Store</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SignUpToNewsletter = () => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:py-24 lg:px-8">
      <div className="px-6 py-6 bg-green-700 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div className="xl:w-0 xl:flex-1">
          <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
            Stay up-to-date with news and releases
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-green-200">
            Subscribe to get email updates and notifications when new learning resources
            and exclusive content become available.
          </p>
        </div>
        <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          <SubscriptionForm darkBackground />
        </div>
      </div>
    </div>
  </div>
);

const SearchSuggestions = () => (
  <div className="mt-3 text-sm text-gray-500 text-left">
    Try "<DictLink className="text-gray-500" search="hello" />",{' '}
    "<DictLink className="text-gray-500" search="konnichiwa"/>"{' '}
    or "<DictLink className="text-gray-500" search="こんにちは" />"
  </div>
);

const Home = () => {
  const [typing, setTyping] = useState(true);
  const [search, setSearch] = useState('');
  return (
    <div>
      <Maker />
      <GiveFeedback />
      <SEO title="Kuma Learn - Learn Japanese Visually" />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Menu />

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Learn Japanese</span> <span className="block text-green-600 xl:inline">
                    {typing && (
                      <Typist
                        cursor={{
                          show: true,
                          blink: true,
                          element: '|',
                          hideWhenDone: true,
                          hideWhenDoneDelay: 800,
                        }}
                        onTypingDone={() => {
                          setTimeout(() => {
                            setTyping(false);
                            setTyping(true);
                          }, 2500);
                        }}
                      >
                        {CAROUSEL.reduce((acc, c, cIdx) => {
                          if (cIdx === CAROUSEL.length - 1) {
                            return [
                              ...acc,
                              <span key={`${c}-text`}>{c}</span>,
                            ];
                          }
                          return [
                            ...acc,
                            <span key={`${c}-text`}>{c}</span>,
                            <Typist.Backspace key={`${c}-cursor`} count={c.length} delay={1000} />,
                          ];
                        }, [])}
                      </Typist>
                    )}
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Whether you&apos;re learning Japanese to watch anime or to visit Japan,
                  we&apos;ve got you covered!
                </p>
                <p className="mt-1 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-2 md:text-xl lg:mx-0">
                  Look up words with our Japanese-English picture dictionary and
                  learn vocab with eBooks containing hand-picked words.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="w-full sm:w-4/5 sm:flex sm:items-end">
                    <div className="w-full sm:w-3/4">
                      <label for="search" className="block text-left text-sm font-medium text-gray-700">Search picture dictionary</label>
                      <input
                        id="search"
                        name="search"
                        type="search"
                        required
                        className="w-full px-5 py-3 mt-2 shadow-sm border-4 border-gray-300 rounded-md h-12 focus:ring-green-500 focus:border-green-500"
                        placeholder={'Search in English, Japanese or romaji...'}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            window.open(
                              `https://app.kumalearn.com/dictionary/${encodeURIComponent(search)}?utm_medium=web&utm_source=www&utm_campaign=landing`,
                              '_blank',
                            );
                          }
                        }}
                        value={search}
                      />
                      <div className="block sm:hidden">
                        <SearchSuggestions />
                      </div>
                    </div>
                    <div className="w-full rounded-md shadow mt-4 sm:mt-0 sm:ml-4 sm:w-1/4">
                      <a
                        href={`https://app.kumalearn.com/dictionary/${encodeURIComponent(search)}?utm_medium=web&utm_source=www&utm_campaign=landing`}
                        target="_blank"
                        rel="noreferrer nofollow"
                        className="flex items-center justify-center px-8 h-12 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:text-lg"
                      >
                        Search
                      </a>
                    </div>
                  </div>
                </div>
                <div className="hidden sm:block">
                  <div className="sm:w-4/5">
                    <SearchSuggestions />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div className="py-8 bg-gradient-to-r from-green-500 to-blue-500 lg:flex lg:flex-row lg:justify-center lg:w-full lg:h-full">
            <img className="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-1/2 lg:h-full" src={coverCards} alt="Kuma Learn"/>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-end">
          <figcaption className="mt-3 flex items-center text-sm text-gray-500">
            <svg className="flex-none w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
            </svg>
            <span className="ml-2 text-xs"><Link className="underline" to="/attributions">Attributions</Link></span>
          </figcaption>
        </div>
      </div>

      <SocialProof />

      <div className="mt-24 pb-8 sm:mt-32">
        <Features />
      </div>

      <SignUpToNewsletter />

      <div id="faqs">
        <Faqs />
      </div>
    </div>
  );
};

const IndexPage = () => (
  <>
    <Helmet>
      <script>
        {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
          analytics.load("phwED5FXq056RtbJDj1W5ABht5W1Ph5c");
          analytics.page();
          }}();
        `}
      </script>
    </Helmet>
    <Home />
    <Footer />
  </>
);

export default IndexPage;
